import VueAgile from './Agile.vue'

const install = (Vue) => {
	Vue.component('agile', VueAgile)
}

export default {
	install
}

export { VueAgile }
